import cx from 'classnames';
import { type ChangeEvent, useEffect, useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { IconButton } from '@components/iconButton/IconButton';
import { successToast } from '@components/toasts/Toasts';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlans } from 'restaurantAdmin/hooks/useAdminFloorPlans';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import { PageHeader } from 'restaurantAdmin/layout/PageHeader';
import typography from '~styles/typography.scss';
import { updateFloorPlans } from './apiHelpers';
import styles from './FloorPlansPage.scss';

export const FloorPlansPage = () => {
  const { id: restaurantId } = useRestaurant();

  const { floorPlans, isLoading, fetchFloorPlans } = useAdminFloorPlans(false);

  const [editMode, setEditMode] = useState(false);
  const [floorPlansForEditing, setFloorPlansForEditing] = useState<
    FloorPlanData[]
  >([]);

  useEffect(() => {
    if (!isLoading) {
      setFloorPlansForEditing(floorPlans);
    }
  }, [isLoading]);

  const allowReordering = floorPlans.length > 1 && editMode;

  const decrementPosition = (oldIndex: number) => {
    if (!allowReordering) {
      return;
    }

    if (oldIndex === 0) {
      const newOrder = [
        ...floorPlansForEditing.slice(1),
        floorPlansForEditing[0],
      ];

      setFloorPlansForEditing(newOrder);
    } else {
      const newIndex = oldIndex - 1;
      const newOrder = floorPlansForEditing
        .toSpliced(oldIndex, 1)
        .toSpliced(newIndex, 0, floorPlansForEditing[oldIndex]);

      setFloorPlansForEditing(newOrder);
    }
  };

  const incrementPosition = (oldIndex: number) => {
    if (!allowReordering) {
      return;
    }

    if (oldIndex === floorPlansForEditing.length - 1) {
      const newOrder = [
        floorPlansForEditing[oldIndex],
        ...floorPlansForEditing.slice(0, oldIndex),
      ];

      setFloorPlansForEditing(newOrder);
    } else {
      const newIndex = oldIndex + 1;
      const newOrder = floorPlansForEditing
        .toSpliced(oldIndex, 1)
        .toSpliced(newIndex, 0, floorPlansForEditing[oldIndex]);

      setFloorPlansForEditing(newOrder);
    }
  };

  const renameFloorPlan = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    floorPlansForEditing[index].name = e.target.value;
  };

  const onCancel = () => {
    setEditMode(false);
    fetchFloorPlans();
  };

  const onSave = async () => {
    await updateFloorPlans(
      restaurantId,
      floorPlansForEditing.map(({ id, name }) => ({ id, name })),
    );

    setEditMode(false);
    successToast({ message: 'Changes saved!' });
  };

  return (
    <>
      <PageHeader category="Operations" title="Floor Plans">
        {!editMode && (
          <Button
            variant={ButtonVariants.Secondary}
            onClick={() => setEditMode(true)}
            label="Edit"
          />
        )}
        {editMode && (
          <>
            <Button
              variant={ButtonVariants.Primary}
              onClick={onSave}
              label="Save"
            />
            <Button
              variant={ButtonVariants.Tertiary}
              onClick={onCancel}
              label="Cancel"
            />
          </>
        )}
      </PageHeader>
      <PageContent>
        <ul className={styles.floorPlanList}>
          {floorPlansForEditing.map((fp, i) => (
            <li key={fp.id}>
              <Card className={styles.floorPlanCard}>
                <div className={styles.primaryContent}>
                  <IconButton
                    iconName="chevronLeft"
                    className={cx({
                      [styles.reorderArrow]: true,
                      [styles.hidden]: !allowReordering,
                    })}
                    ariaLabel={`move ${fp.name} toward beginning of list`}
                    onClick={() => decrementPosition(i)}
                  />
                  <FloorPlan
                    className={styles.floorPlan}
                    disableControls
                    backgroundSrc={fp.backgroundSrc}
                    floorPlanTablesRenderer={floorPlanTablesRendererFactory({
                      tables: fp.floorPlanTables,
                    })}
                  />
                  <IconButton
                    iconName="chevronRight"
                    className={cx({
                      [styles.reorderArrow]: true,
                      [styles.hidden]: !allowReordering,
                    })}
                    ariaLabel={`move ${fp.name} toward end of list`}
                    onClick={() => incrementPosition(i)}
                  />
                </div>
                <div className={cx(typography.c2, styles.floorPlanName)}>
                  <svg className={styles.floorPlanPositionContainer}>
                    <circle />
                  </svg>
                  <span className={cx(styles.floorPlanPosition, typography.t2)}>
                    {i + 1}
                  </span>
                  {editMode ? (
                    <input
                      data-testid={`floor-plan-${i}-name`}
                      defaultValue={fp.name}
                      onChange={(e) => renameFloorPlan(e, i)}
                      maxLength={50}
                    />
                  ) : (
                    fp.name
                  )}
                </div>
              </Card>
            </li>
          ))}
        </ul>
      </PageContent>
    </>
  );
};
