// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorPlansPage__floorPlanList___ou0FF{display:flex;gap:8px}.FloorPlansPage__floorPlanCard___T8f_h{border-radius:var(--radius_medium)}.FloorPlansPage__floorPlanCard___T8f_h .FloorPlansPage__primaryContent___bIDxy{display:flex;justify-content:space-between}.FloorPlansPage__floorPlanName___UqrgL{margin-top:16px}.FloorPlansPage__floorPlanName___UqrgL input{border-radius:var(--radius_small);background-color:var(--darkGrey100);color:var(--darkBlue)}.FloorPlansPage__floorPlanPositionContainer___w8km8{height:20px;width:20px}.FloorPlansPage__floorPlanPositionContainer___w8km8 circle{r:9px;cx:9px;cy:9px;fill:var(--hairline)}.FloorPlansPage__floorPlanPosition___o9FfI{position:relative;left:-14px}.FloorPlansPage__floorPlan___h0Yz_{padding:16px;width:360px;height:250px;background-color:var(--primary400);border-radius:var(--radius_medium)}.FloorPlansPage__reorderArrow___HJR42{background-color:rgba(0,0,0,0)}.FloorPlansPage__reorderArrow___HJR42:active{background-color:rgba(0,0,0,0)}.FloorPlansPage__reorderArrow___HJR42.FloorPlansPage__hidden___bSccq{visibility:hidden;cursor:default}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/floorPlans/FloorPlansPage.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,OAAA,CAGF,uCACE,kCAAA,CAEA,+EACE,YAAA,CACA,6BAAA,CAIJ,uCACE,eAAA,CAEA,6CACE,iCAAA,CACA,mCAAA,CACA,qBAAA,CAIJ,oDACE,WAAA,CACA,UAAA,CAEA,2DACE,KAAA,CACA,MAAA,CACA,MAAA,CACA,oBAAA,CAIJ,2CACE,iBAAA,CACA,UAAA,CAGF,mCACE,YAAA,CACA,WAAA,CACA,YAAA,CACA,kCAAA,CACA,kCAAA,CAGF,sCACE,8BAAA,CAEA,6CACE,8BAAA,CAGF,qEACE,iBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floorPlanList": "FloorPlansPage__floorPlanList___ou0FF",
	"floorPlanCard": "FloorPlansPage__floorPlanCard___T8f_h",
	"primaryContent": "FloorPlansPage__primaryContent___bIDxy",
	"floorPlanName": "FloorPlansPage__floorPlanName___UqrgL",
	"floorPlanPositionContainer": "FloorPlansPage__floorPlanPositionContainer___w8km8",
	"floorPlanPosition": "FloorPlansPage__floorPlanPosition___o9FfI",
	"floorPlan": "FloorPlansPage__floorPlan___h0Yz_",
	"reorderArrow": "FloorPlansPage__reorderArrow___HJR42",
	"hidden": "FloorPlansPage__hidden___bSccq"
};
export default ___CSS_LOADER_EXPORT___;
